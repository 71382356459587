import { COMMENT_BASE_FRAGMENT } from '../Comment/Base';

export const FEED_POST_FULL_FRAGMENT = `
    fragment feedPostFullFragment on FeedPost {
        uid
        _isLiked(myUid: "%authUser%")
        _likesCount
        _repostsCount
        repostPost {
            _feedItem {
                uid
                triggered {
                    __typename
                    ... on FeedPost {
                        uid
                        _repostsCount
                    }
                }
            }
        }
        comments {
            uid
        }
        _topLevelComments {
            ...commentBaseFragment
            childrenComments (orderBy:createdTime_asc) {
                ...commentBaseFragment
                childrenComments (orderBy:createdTime_asc) {
                    ...commentBaseFragment
                }
            }
        }
    }
    ${COMMENT_BASE_FRAGMENT}
  `;
